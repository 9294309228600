/* eslint-disable react/no-danger */
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { Wrapper } from './TextContent.style';

/**
 * TextContent.
 */
const TextContent = ({ sections, headline, wideTextBlock = false }) => {
  const title = headline || null;

  return (
    <Wrapper container columnSpacing={4} wideTextBlock={wideTextBlock}>
      {!wideTextBlock && (
        <Grid item xs={12} md={4} className="title-column">
          {title}
        </Grid>
      )}
      <Grid item xs={12} md={wideTextBlock ? 12 : 8}>
        {sections.map((section, id) => (
          <div key={id}>{section}</div>
        ))}
      </Grid>
    </Wrapper>
  );
};

TextContent.propTypes = {
  /** Content to be displayed. */
  sections: PropTypes.array.isRequired,
  /** Headline of the section */
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  wideTextBlock: PropTypes.bool,
};

TextContent.defaultProps = {
  headline: '',
};

export default TextContent;
